import { NoGuidelinesApplyConstants } from '@utils/constants'
import {
  sortDocumentedGuidelines,
  isGuidelineDocumented,
  removeDocumentedGuideline,
  documentedGuidelineCountByCode,
  previewIndicationsNodes,
  defaultCode,
  findIndicationIndex,
  handleSelectionStatus,
} from '@utils/document-guideline-util'

export const state = {
  documentedIndications: [],
  documentedIndicationNotes: {},
  episodeAdditionalNote: '',
  documentedGuidelines: [],
  toggleIndicationSelected: false,
  overallStatus: [],
  rootNodes: {},
  previewDocumentedGuidelines: [],
}

export const getters = {
  documentedIndications: (s) => s.documentedIndications,
  documentedIndicationNotes: (s) => s.documentedIndicationNotes,
  previewDocumentedGuidelines: (s) => s.previewDocumentedGuidelines,
  episodeAdditionalNote: (s) => s.episodeAdditionalNote,
  documentedGuidelines: (s) => s.documentedGuidelines,
  toggleIndicationSelected: (s) => s.toggleIndicationSelected,
  hasSummary: (s) => (item) => {
    const guideline = s.previewDocumentedGuidelines.find(
      (x) => x.code === item.code && x.guidelineHsim === item.guidelineHsim
    )
    return guideline?.allIndicationPaths?.length > 0
  },
  hasDocumentation: (s) => (item) => {
    const guideline = s.previewDocumentedGuidelines.find(
      (i) => i.code === item.searchOutput.guidelineCode
    )
    return (
      guideline?.allIndicationPaths?.length > 0 ||
      guideline?.guidelineHsim === NoGuidelinesApplyConstants.Hsim
    )
  },
  overallStatus: (s) => s.overallStatus,
}

export const mutations = {
  ADD_DOCUMENTED_INDICATION_NOTE(s, newValue) {
    if (newValue?.code === undefined || newValue?.code === '') {
      newValue.code = newValue?.hsim
    }
    if (s.documentedIndicationNotes[newValue?.code] === undefined) {
      s.documentedIndicationNotes[newValue?.code] = {
        guidelineHsim: newValue?.hsim,
        nodes: [{ node: newValue?.node, note: newValue?.note }],
      }
    } else {
      const nodeSelected = s.documentedIndicationNotes[
        newValue?.code
      ]?.nodes?.find((n) => n.node.uid === newValue?.node.uid)

      if (nodeSelected === undefined) {
        s.documentedIndicationNotes[newValue?.code].nodes.push({
          node: newValue?.node,
          note: newValue?.note,
        })
      } else {
        if (newValue?.note?.length === 0) {
          const nodeIndex = s.documentedIndicationNotes[
            newValue?.code
          ]?.nodes?.findIndex((n) => n.node.uid === newValue?.node.uid)

          if (nodeIndex > -1) {
            s.documentedIndicationNotes[newValue.code].nodes.splice(
              nodeIndex,
              1
            )
          }
        } else {
          nodeSelected.note = newValue?.note
        }
      }
    }
  },

  PRECHECK_DOCUMENTED_GUIDELINE(s, { code, guidelineHsim }) {
    if (
      !isGuidelineDocumented(s.documentedGuidelines, code, guidelineHsim) &&
      documentedGuidelineCountByCode(s.documentedGuidelines, code) > 0
    ) {
      const updatedDatasets = removeDocumentedGuideline(
        s.documentedGuidelines,
        s.documentedIndicationNotes,
        s.documentedIndications,
        code
      )

      s.documentedGuidelines = updatedDatasets.documentedGuidelines
      s.documentedIndicationNotes = updatedDatasets.documentedIndicationNotes
      s.documentedIndications = updatedDatasets.documentedIndications
    }
  },

  REMOVE_ALL_DOCUMENTED_BY_CODE(s, { code }) {
    const updatedDatasets = removeDocumentedGuideline(
      s.documentedGuidelines,
      s.documentedIndicationNotes,
      s.documentedIndications,
      s.overallStatus,
      code
    )

    s.documentedGuidelines = updatedDatasets.documentedGuidelines
    s.documentedIndicationNotes = updatedDatasets.documentedIndicationNotes
    s.documentedIndications = updatedDatasets.documentedIndications
    s.overallStatus = updatedDatasets.documentedIndications
    delete s.rootNodes[code]
  },

  UPDATE_PREVIEW_DOCUMENTED_GUIDELINES_NODES(s, clinicalInputs) {
    const previewDocumentationNodes = previewIndicationsNodes(
      s.documentedIndications,
      s.documentedIndicationNotes,
      s.documentedGuidelines,
      clinicalInputs,
      s.rootNodes
    )
    s.previewDocumentedGuidelines = previewDocumentationNodes
  },

  EPISODE_ADDITIONAL_NOTE(s, newValue) {
    s.episodeAdditionalNote = newValue.note
  },
  UPDATE_DOCUMENTED_INDICATIONS(
    s,
    {
      node,
      code,
      guidelineHsim,
      newSelectedStatus,
      userSelected,
      clinicalInput,
    }
  ) {
    code = defaultCode(code, guidelineHsim)

    s.toggleIndicationSelected = null

    const indexOfIndication = findIndicationIndex(
      s.documentedIndications,
      node,
      code,
      guidelineHsim
    )

    handleSelectionStatus({
      s,
      index: indexOfIndication,
      status: newSelectedStatus,
      node,
      code,
      guidelineHsim,
      userSelected,
      clinicalInput,
    })
  },
  UPDATE_DOCUMENTED_GUIDELINE(
    s,
    {
      guidelineHsim,
      code,
      guidelineTitle,
      codeDescription,
      sortOrder,
      contentEdition,
    }
  ) {
    const indexOfCode = s.documentedGuidelines?.findIndex(function (g) {
      return g.code === code
    })

    if (indexOfCode !== -1) {
      s.documentedGuidelines.splice(indexOfCode, 1)
    }

    s.documentedGuidelines = [
      ...s.documentedGuidelines,
      {
        guidelineHsim,
        code,
        guidelineTitle,
        codeDescription,
        contentEdition,
      },
    ]

    s.documentedGuidelines = sortDocumentedGuidelines(
      s.documentedGuidelines,
      sortOrder
    )
  },

  UPDATE_OVERALL_STATUS(s, { status, code, guidelineHsim }) {
    const indexOfGuideline = s.overallStatus?.findIndex(function (g) {
      return g.code === code && g.guidelineHsim === guidelineHsim
    })

    if (indexOfGuideline > -1) {
      s.overallStatus.splice(indexOfGuideline, 1)
    }

    s.overallStatus = [...s.overallStatus, { status, code, guidelineHsim }]
  },
  SET_ROOT_NODES(s, { code, rootNodes }) {
    s.rootNodes[code] = rootNodes
  },
}

export const actions = {
  updateIndicationSelection(
    { commit },
    {
      node,
      newSelectedStatus,
      guidelineHsim,
      code,
      guidelineTitle,
      codeDescription,
      sortOrder,
      contentEdition,
      rootNodes,
    }
  ) {
    commit('SET_ROOT_NODES', rootNodes)
    commit('PRECHECK_DOCUMENTED_GUIDELINE', { code, guidelineHsim })
    commit('UPDATE_DOCUMENTED_INDICATIONS', {
      node,
      code,
      guidelineHsim,
      newSelectedStatus,
      userSelected: false,
    })
    commit('UPDATE_DOCUMENTED_GUIDELINE', {
      guidelineHsim,
      code,
      guidelineTitle,
      codeDescription,
      sortOrder,
      contentEdition,
    })
  },

  toggleIndicationSelection(
    { commit, getters },
    {
      node,
      guidelineHsim,
      code,
      guidelineTitle,
      codeDescription,
      sortOrder,
      contentEdition,
      rootNodes,
      clinicalInput,
    }
  ) {
    commit('SET_ROOT_NODES', rootNodes)
    commit('PRECHECK_DOCUMENTED_GUIDELINE', { code, guidelineHsim })

    commit('UPDATE_DOCUMENTED_INDICATIONS', {
      node,
      code,
      guidelineHsim,
      newSelectedStatus: undefined,
      userSelected: true,
      clinicalInput,
    })

    commit('UPDATE_DOCUMENTED_GUIDELINE', {
      guidelineHsim,
      code,
      guidelineTitle,
      codeDescription,
      sortOrder,
      contentEdition,
    })

    return getters.toggleIndicationSelected
  },

  updateIndicationNote(
    { commit },
    {
      node,
      note,
      code,
      hsim,
      guidelineTitle,
      codeDescription,
      sortOrder,
      contentEdition,
      rootNodes,
    }
  ) {
    commit('SET_ROOT_NODES', rootNodes)
    commit('PRECHECK_DOCUMENTED_GUIDELINE', { code, guidelineHsim: hsim })

    commit('ADD_DOCUMENTED_INDICATION_NOTE', {
      node: node,
      note: note,
      code,
      hsim,
      guidelineTitle,
      codeDescription,
    })
    commit('UPDATE_DOCUMENTED_GUIDELINE', {
      guidelineHsim: hsim,
      code,
      guidelineTitle,
      codeDescription,
      sortOrder,
      contentEdition,
    })
  },

  updateEpisodeAdditionalNote({ commit }, { note }) {
    commit('EPISODE_ADDITIONAL_NOTE', { note: note })
  },

  removeAllDocumentedByCode({ commit }, code) {
    commit('REMOVE_ALL_DOCUMENTED_BY_CODE', { code: code })
  },

  addGuidelineToDocumentedList({ commit }, data) {
    commit('PRECHECK_DOCUMENTED_GUIDELINE', {
      code: data.code,
      guidelineHsim: data.guidelineHsim,
    })
    commit('UPDATE_DOCUMENTED_GUIDELINE', data)
  },

  setOverallStatus({ commit }, data) {
    commit('UPDATE_OVERALL_STATUS', data)
  },

  updatePreview({ commit }, clinicalInputs) {
    commit('UPDATE_PREVIEW_DOCUMENTED_GUIDELINES_NODES', clinicalInputs)
  },

  setRootNodes({ commit }, { code, rootNodes }) {
    commit('SET_ROOT_NODES', { code, rootNodes })
  },
}
